type ManualGroupName = 'coffee_syphon' | 'paper_drip' | 'nel_drip'

type ManualSectionData = {
  [K in ManualGroupName]: {
    slug: K
    heading: string
    description: string
    link: string
  }
}

const data: ManualSectionData = {
  coffee_syphon: {
    slug: 'coffee_syphon',
    heading: 'サイフォンの淹れ方',
    description:
      '医療用品に着想を得て発明されたサイフォン。フラスコやアルコールランプなど、化学の実験を思わせる器具は見た目も美しく、絵になります。高温かつ短時間で抽出するため、香りがよいコーヒーに仕上がるのも魅力です。',
    link: '#',
  },
  paper_drip: {
    slug: 'paper_drip',
    heading: 'ペーパードリップの淹れ方',
    description:
      '焦らず根気よくポタポタとお湯を注ぐことで、コーヒー豆がもつ風味と旨味を最大限に引き出し、名門フィルターの中央の穴から放射状に伸びたリブが雑味のないクリアな味わいに仕上げてくれます。',
    link: '#',
  },
  nel_drip: {
    slug: 'nel_drip',
    heading: 'ネルドリップの淹れ方',
    description:
      'ネルを使った布フィルターは、管理に手間がかかりますが本格的なコーヒーの風味を確実に引き出すことができます。おいしさのためなら手間を惜しまない、こだわり派の抽出方法です。',
    link: '#',
  },
}

export default data
