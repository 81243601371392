type ManualGroupName = 'coffee_syphon' | 'paper_drip' | 'nel_drip'

type ManualStepData = {
  [key: string]: {
    slug: ManualGroupName
    items: {
      image: string
      overview?: string
      description: string
      note?: string
    }[]
  }
}

const data: ManualStepData = {
  coffee_syphon_01: {
    slug: 'coffee_syphon',
    items: [
      {
        image: 'coffee_syphon-01.jpg',
        overview: 'ペーパー濾過紙をセットする',
        description: 'ペーパー濾過器にサイフォン用の濾過紙を差し込みます。',
      },
      {
        image: 'coffee_syphon-02.jpg',
        overview: '器具を回して留める',
        description: '濾過紙を挟むように器具を回して留めます。',
        note: '※強く締めすぎると外れにくくなるので、軽く留める程度で十分です。',
      },
      {
        image: 'coffee_syphon-03.jpg',
        overview: 'ロートの底に濾過器をセットする',
        description:
          'ロートの底に濾過器を入れます。ボールチェーンはロート管を通して、フックを先端に静かにひっかけます。',
        note: '※フックを強くはじくとロート管が割れますので注意してください。',
      },
    ],
  },
  coffee_syphon_02: {
    slug: 'coffee_syphon',
    items: [
      {
        image: 'coffee_syphon-04.jpg',
        overview: 'コーヒー粉を入れる',
        description: 'ロートにコーヒー粉を入れます。',
      },
      {
        image: 'coffee_syphon-05.jpg',
        overview: 'コーヒー粉をならす',
        description: 'ロートを軽くゆすり、コーヒー粉を平らにならします。',
      },
      {
        image: 'coffee_syphon-06.jpg',
        overview: 'フラスコに熱湯を入れ、加熱する',
        description:
          'フラスコに沸騰したての熱湯を入れ、アルコールランプに火を付けてフラスコの底の中心に炎が当たるように置きます。ロートをフラスコの上に斜めにのせておきます。',
        note: '※火をつける前に必ずフラスコ全体を乾いた布で拭いてください。濡れていると割れることがあります。',
      },
      {
        image: 'coffee_syphon-07.jpg',
        overview: '気泡が出てきたらロートを挿し込む',
        description: 'ボールチェーンから細かな気泡がポツポツと出てきたら、フラスコにロートを挿し込みます。',
        note: '※ロートを強く押し込む必要はありません。',
      },
      {
        image: 'coffee_syphon-08.jpg',
        overview: 'お湯が上がるのを待つ',
        description: 'ロートの中にお湯が上がってくるのを待ちます。',
        note: '※空焚きしないようにフラスコの底にはお湯が少し残っています。',
      },
      {
        image: 'coffee_syphon-09.jpg',
        overview: '粉を沈める',
        description:
          '湯面の高さが一定になったら、竹べらの面を使って軽くはたくようにして、上部に浮かんだ粉をまんべんなくお湯の中に沈めます。',
        note: '※粉にお湯を十分に浸透させます。',
      },
      {
        image: 'coffee_syphon-10.jpg',
        overview: '粉をほぐす',
        description: '粉がダマにならないように、竹べらを前後に動かしてほぐします。',
        note: '※ほぐすことでコーヒーの成分が抽出されます。',
      },
      {
        image: 'coffee_syphon-11.jpg',
        overview: '攪拌する',
        description: '竹べらで攪拌し、液体・粉・泡の三層に分けていきます。',
        note: '※粉に負荷をかけないように短い時間でしっかり攪拌します。',
      },
      {
        image: 'coffee_syphon-12.jpg',
        overview: '1分ほど待つ',
        description: 'アルコールランプの火を付けたまま1分ほど待ちます。（2人用は60秒, 3人用は50秒, 4人用は40秒）',
        note: '※途中30秒ほどで粉の間にすき間ができている場合は、竹べらで粉の層を軽く撹拌します。',
      },
      {
        image: 'coffee_syphon-13.jpg',
        overview: 'アルコールランプを外す',
        description: 'アルコールランプを外してフタで火を消し、液体が落ちるのを待ちます。',
      },
      {
        image: 'coffee_syphon-14.jpg',
        overview: '液体がフラスコに移動する',
        description: '最初に黄色い細かい泡が落ちてきて、フラスコへ吸い込まれるように一気に液体が移動します。',
      },
      {
        image: 'coffee_syphon-15.jpg',
        overview: '完成',
        description: 'すべて抽出されたら、できあがりです。',
        note: '※ロートはスタンド側へ倒せば簡単にフラスコから外せます。',
      },
    ],
  },
  paper_drip_01: {
    slug: 'paper_drip',
    items: [
      {
        image: 'paper_drip-01.jpg',
        description: 'ペーパーの圧着部を折り返します。',
      },
      {
        image: 'paper_drip-02.jpg',
        description: '開口部を開いて、折り目のついた部分を指で押さえて伸ばします。',
      },
      {
        image: 'paper_drip-03.jpg',
        description: '先端のはみ出した圧着部を巻き込むように折り返します。',
      },
    ],
  },
  paper_drip_02: {
    slug: 'paper_drip',
    items: [
      {
        image: 'paper_drip-04.jpg',
        overview: 'コーヒー粉を入れる',
        description: 'ペーパーフィルターにコーヒー粉を入れます。一杯につき12g（2人用は24g、4人用は48g）が目安です。',
      },
      {
        image: 'paper_drip-05.jpg',
        overview: 'コーヒー粉をならす',
        description: 'フィルターの側面を手で軽く叩いて、コーヒー粉を平らにならします。',
      },
    ],
  },
  paper_drip_03: {
    slug: 'paper_drip',
    items: [
      {
        image: 'paper_drip-06.jpg',
        overview: 'ポタポタとお湯を注ぐ',
        description:
          '点滴のようにお湯を中央にポタポタと垂らしてゆっくり時間をかけて注ぎます。30～60秒ほどでグラスポットにコーヒー液が落ち始めます。',
      },
      {
        image: 'paper_drip-07.jpg',
        overview: '中央に細くお湯を注ぐ',
        description: 'コーヒー液がグラスポットの底を覆うぐらいまで溜まったら、500円玉の範囲で細くお湯を注ぎます。',
        note: '※抽出量の3分の1程度まで、中央にドーム状の白い泡を膨らませておくことがポイントです。',
      },
      {
        image: 'paper_drip-08.jpg',
        overview: '湯量を増やして注ぎ続ける',
        description: '泡のドームを維持するように、湯量を少しずつ増やして、お湯を注ぐ範囲を広げながら注ぎ続けます。',
        note: '※抽出量の3分の2程度まで、白い泡を崩さないように注ぎ続けます。',
      },
      {
        image: 'paper_drip-09.jpg',
        overview: 'フィルターいっぱいにお湯を注ぐ',
        description: '湯量を静かに一気に増やし、フィルターになみなみとお湯を注いで満水の状態にします。',
        note: '※白い泡は雑味やえぐみなので、表面に浮かし続けます。',
      },
      {
        image: 'paper_drip-10.jpg',
        overview: 'フィルターを外す',
        description: '必要な杯数分のコーヒーが抽出できたら、別の容器にフィルターを外して排水します。',
        note: '※フィルター内のお湯が減ってきたら、静かにお湯を注ぎ足して満水の状態を保ってください。',
      },
      {
        image: 'paper_drip-11.jpg',
        overview: '完成',
        description: '雑味のない美味しいコーヒーの出来上がりです。',
      },
    ],
  },
  nel_drip_01: {
    slug: 'nel_drip',
    items: [
      {
        image: 'nel_drip-01.jpg',
        overview: 'コーヒー粉を入れる',
        description: 'ネルフィルターにコーヒー粉を入れて軽くゆすり、平らにならします。',
      },
      {
        image: 'nel_drip-02.jpg',
        overview: 'ポタポタとお湯を注ぐ',
        description:
          '点滴のようにお湯を中央にポタポタと垂らしてゆっくり時間をかけて注ぎます。60秒ほどでグラスポットにコーヒー液が落ち始めます。',
      },
      {
        image: 'nel_drip-03.jpg',
        overview: '中央から細くお湯を注ぎ続ける',
        description: 'コーヒー液がグラスポットの底を覆うぐらいまで溜まったら、500円玉の範囲で細くお湯を注ぎます。',
        note: '※抽出量の3分の1程度まで、中央にドーム状の白い泡を膨らませておくことがポイントです。',
      },
      {
        image: 'nel_drip-04.jpg',
        overview: 'フィルターいっぱいにお湯を注ぐ',
        description:
          'お湯を注ぐ範囲を広げながら、湯量を少しずつ増やして注ぎ続け、最後はフィルターいっぱいにお湯を注ぎます。',
        note: '※白い泡は雑味やえぐみなので、表面に浮かし続けます。',
      },
      {
        image: 'nel_drip-05.jpg',
        overview: 'フィルターを外す',
        description: '必要な杯数分のコーヒーが抽出できたら、別の容器にフィルターを外して排水します。',
      },
      {
        image: 'nel_drip-06.jpg',
        overview: '完成',
        description: '雑味のない美味しいコーヒーの出来上がりです。',
      },
    ],
  },
}

export default data
